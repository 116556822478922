/* play-regular - latin */
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('@assets/fonts/Play/play-v12-latin-regular.woff') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@assets/fonts/Play/play-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* play-700 - latin */
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('@assets/fonts/Play/play-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@assets/fonts/Play/play-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* 1rem = 10px */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* font-size: 10px; */
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}


body {
  font-family: 'Play', sans-serif;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}


.react-datepicker-wrapper {
  width: 100%;
}