.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: grayscale(100%) brightness(100%) invert(1);
  -webkit-filter: grayscale(100%) brightness(100%) invert(1);
}

/* @media screen and (max-width: 768px) {
  [class*="map"][class*="ua_js_yes"] {
    position: relative;
    bottom: 50px;
  }
} */

[class*="placemark-overlay"][class*="user-selection-none"] {
  margin-left: -10px !important;
}

[class*="placemark-overlay"][class*="user-selection-none"] [class*="image-with-content"] {
  top: -40px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: unset !important;
}

[class*="placemark-overlay"][class*="user-selection-none"] [class*="image-with-content"] [class*="image-with-content-content"] {
  height: 18px !important;
}

[class*="image-with-content-content"] [id*="id_"] {
  display: block;
  width: fit-content;
  border-radius: 30px;
  height: 18px;
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 115%;
  position: relative;
  top: 77px;
  right: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: rgba(255,255,255,.85);
  padding: 0 8px 0 8px;
  margin-top: 0;
}